import React, { useRef } from "react";

import SEO from "../../components/seo";
import Layout from "../../components/layout";
import ProjectHero from "../../modules/hero/project-hero";
import CoverSection from "../../components/project/cover-section";
import ProjectDetails from "../../components/project/project-details";
import Sircles1 from "../../images/sircles/1.jpeg";
import Sircles2 from "../../images/sircles/2.png";
import Sircles3 from "../../images/sircles/3.jpeg";
import Sircles4 from "../../images/sircles/4.jpeg";
import Sircles5 from "../../images/sircles/5.jpeg";
import Sircles6 from "../../images/sircles/6.png";
import FooterMapDetails from "../../components/footer/footer-map-details";

const images = [
  { src: Sircles2, title: "project Sircles2" },
  { src: Sircles3, title: "project Sircles3" },
  { src: Sircles4, title: "project Sircles4" },
  { src: Sircles5, title: "project Sircles5" },
  { src: Sircles6, title: "project Sircles6" },
];

const ProjectTemplate = ({ location }) => {
  const seo = {
    pageUrl: "/projects/sircles",
    pageTitle: "Sircles | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "Sircles | Vello | A full-service creative agency.",
  };

  const pageLayoutRef = useRef();
  const coverSectionUnique = `sircles-section`;

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ProjectHero
          title={"Sircles"}
          client={"Sircles"}
          output={"Mobile App"}
          publishDate={"2020"}
          externalLink={"https://sircles.com/"}
          coverSectionUnique={coverSectionUnique}
          pageLayoutRef={pageLayoutRef}
        />
        <CoverSection
          coverImage={Sircles1}
          coverSectionUnique={coverSectionUnique}
        />

        <ProjectDetails
          overview={
            "Sircles is recommendations from friends and people you trust. Store your favorite restaurants, movies, nail salon, etc... Easily find and map your friends' favorites. Get rewarded for sharing. Create your Sircle of Trust. No longer rely on Yelp reviews written by strangers. They are often fake or false. Sircle is, and always will be, a positive, sharing community."
          }
          ourSolution={
            "Sircles recently skyrocketed because of their highly successful crowdfunding campaign. They needed help bringing their app to a few thousand in beta to be able to service much more people when going to production. We assisted in migrating their backend architecture from REST to GraphQL and will be continuing to maintain, optimize and automate their backend to scale to handle multi-million concurrent users, which we believe they will have."
          }
          highlightedText={
            "With the switch from REST to GraphQL, we were able to speed up network calls and ultimately the app by 3x. As optimzations are done daily, we are aiming for 5x the prior speed with 10x the original users."
          }
          images={images}
          nextPageLink={"/projects/blitz"}
          pageLayoutRef={pageLayoutRef}
        />
        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default ProjectTemplate;
